<span class="primary">
  <ng-container [ngSwitch]="application.status">
    <mat-icon *ngSwitchCase="'APPROVED'">check_circle</mat-icon>
    <mat-icon *ngSwitchCase="'APPROVED_BY_PARTNER'">verified</mat-icon>
    <mat-icon *ngSwitchCase="'REJECTED_BY_PARTNER'">cancel</mat-icon>
    <mat-icon *ngSwitchCase="'AWAITING_APPROVAL_BY_PARTNER'">pending</mat-icon>
    <mat-icon *ngSwitchCase="'CONFIRMED'">done_all</mat-icon>
    <mat-icon *ngSwitchCase="'REJECTED'">cancel</mat-icon>
    <mat-icon *ngSwitchCase="'REPAID'">attach_money</mat-icon>
    <mat-icon *ngSwitchCase="'REGULAR'">check_circle_outline</mat-icon>
    <mat-icon *ngSwitchCase="'OVERDUE'">warning</mat-icon>
    <mat-icon *ngSwitchCase="'LOSS'">trending_down</mat-icon>
    <mat-icon *ngSwitchCase="'NEW'">fiber_new</mat-icon>
    <mat-icon *ngSwitchCase="'PROCESSING'">timelapse</mat-icon>
    <mat-icon *ngSwitchCase="'COMPLETED'">check_circle_outline</mat-icon>
  </ng-container>
</span>
