<mat-card>
    <mat-card-header>
        <mat-card-title>
            {{title}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        {{message}}
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-stroked-button type="button" (click)="close()">
            {{'global.close'|translate}}
        </button>
    </mat-card-actions>
</mat-card>
