<ng-container *ngIf="displayError; else container">
    <div class="error-message-container">
        <span class="title">{{errorMessage}}</span>
        <span>Моля, последвайте линка от полученият имейл. или се свържете с нас на телефон <a
                href="tel:070018038">0700&nbsp;18&nbsp;038</a> или на имейл <a
                href="mailto: support@itfgroupbg.com">support&#64;itfgroupbg.com</a></span>
    </div>
</ng-container>
<ng-template #container>
    <div class="density-3 form-layout mat-stepper-vertical-padding credit-confirmation-stepper">
        <div class="header">
            <div class="title">
                <mat-icon color="primary">person</mat-icon>
                <span>Здравейте, {{ application?.clientName }}!</span>
            </div>
            <div class="subtitle">
                <span>Заявката ви за кредит #{{application?.id}} е одобрена!</span>
                <ng-container *ngIf="!application?.idCardVerified && application.status === 'APPROVED'">
                    <br>
                    <span>Следвайте стъпките, за да преминете към потвърждаване на договора.</span>
                </ng-container>
                <!-- <br>
                <span>Следвайте стъпките, за да преминете към потвърждаване на договора.</span> -->
            </div>
        </div>

        <mat-stepper linear orientation="vertical" #matVerticalStepper animationDuration="0"
            *ngIf="(stepsState$ | async) as steps">
            <mat-step [stepControl]="firstFormGroup" [hasError]="steps[stepsTypes.ID_UPLOAD].hasError"
                [errorMessage]="steps[stepsTypes.ID_UPLOAD].errorMessage" color="primary">
                <ng-template matStepLabel>{{steps[stepsTypes.ID_UPLOAD].label}}</ng-template>
                <ng-template matStepperIcon="error">
                    <mat-icon>pending</mat-icon>
                </ng-template>
                <ng-container *ngIf="settings.uploadDocumentsShown">
                    <itfg-dynamic-form [fields]="includedFields"
                        (formSubmitted)="onDocumentsSubmit($event)"></itfg-dynamic-form>
                </ng-container>
                <ng-container *ngIf="this.application.idCardVerified === null">
                    <div class="flex-layout-row align-items-center">
                        Моля, изчакайте...
                        <button [disabled]="refreshBtnDisabled" mat-icon-button (click)="resolveRefreshApliication()">
                            <mat-icon color="primary">refresh</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
                <ng-template matStepLabel>Преглед на договор</ng-template>
                <ng-container *ngTemplateOutlet="applicationDetails"></ng-container>
                <ng-container *ngIf="settings.documentsShown && application?.status === 'APPROVED'">
                    <div class="documents-panel" #documentsPanel>
                        <div class="documents-list-container">
                            <mat-list>
                                <div class="document-row" *ngFor="let document of documents">
                                    <mat-list-item (click)="documentClicked(document)">
                                        <mat-icon matListItemIcon>description</mat-icon>
                                        <div matListItemTitle>
                                            <span>{{ document.templateName }}</span>
                                        </div>
                                        <div matListItemLine>{{ document.date | date }}</div>
                                    </mat-list-item>
                                </div>
                            </mat-list>
                        </div>
                    </div>
                </ng-container>
                <div>
                    <div class="actions-container">
                        <mat-checkbox [(ngModel)]="acceptedAgreement">
                            <span>
                                Не попадам в категорията лица по смисъла на <a class="primary" href="/pep" target="_blank">чл. 36 от ЗМИП
                                </a> (видна политическа личност и свързани с тях лица).
                            </span>
                        </mat-checkbox>
                    </div>
                    <div class="form-actions" *ngIf="application?.status === 'APPROVED'">
                        <button mat-raised-button color="primary" (click)="confirmClicked()">
                            {{ 'global.confirm' | translate }}
                        </button>
                        <button mat-raised-button color="warn" (click)="rejectClicked()">
                            {{ 'global.reject' | translate }}
                        </button>
                    </div>
                </div>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" [completed]="steps[stepsTypes.DONE].completed">
                <ng-template matStepLabel>Готово!</ng-template>
                <ng-container *ngTemplateOutlet="applicationDetails"></ng-container>
            </mat-step>
        </mat-stepper>

    </div>
</ng-template>

<ng-template #applicationDetails>
    <div class="form-fields-layout">
        <div class="flex-layout-row">
            <mat-form-field>
                <mat-label>Статус</mat-label>
                <input matInput readonly type="text"
                    [value]="'application.statusType.' + application?.status | translate" />
                <div matPrefix>
                    <itfg-application-status-icon [application]="application"></itfg-application-status-icon>
                </div>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Главница</mat-label>
                <input matInput readonly type="text" [value]="application?.principal | currency" />
                <mat-icon matPrefix>payments</mat-icon>
            </mat-form-field>
        </div>
        <div class="flex-layout-row">
            <mat-form-field>
                <mat-label>Дата на договора</mat-label>
                <input matInput readonly type="text" [value]="application?.createTime | date : 'dd.MM.YYYY'" />
                <mat-icon matPrefix>calendar_today</mat-icon>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Договор до</mat-label>
                <input matInput readonly type="text" [value]="application?.dueDate | date : 'dd.MM.YYYY'" />
                <mat-icon matPrefix>calendar_month</mat-icon>
            </mat-form-field>
        </div>
        <div class="flex-layout-row">
            <mat-form-field>
                <mat-label>Вноска</mat-label>
                <input matInput readonly type="text" [value]="application?.installmentAmount | currency" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Брой вноски</mat-label>
                <input matInput readonly type="text" [value]="application?.period" />
                <mat-icon matPrefix>date_range</mat-icon>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<!-- 
<div class="density-3">
    <ng-container *ngIf="displayError">
        <div class="error-message-container">
            <span class="title">{{errorMessage}}</span>
            <span>Моля, последвайте линка от полученият имейл. или се свържете с нас на телефон <a
                    href="tel:070018038">0700&nbsp;18&nbsp;038</a> или на имейл <a
                    href="mailto: support@itfgroupbg.com">support@itfgroupbg.com</a></span>
        </div>
    </ng-container>

    <mat-card class="form-layout" *ngIf="application">
        <mat-card-header>
            <mat-card-title *ngIf="settings.documentsShown || settings.uploadDocumentsShown">
                <span class="documents-preview-title">
                    <mat-icon color="primary">person</mat-icon>
                    Здравейте, {{ application?.clientName }}!
                </span>
            </mat-card-title>
            <mat-card-subtitle *ngIf="settings.documentsShown">
                Заявка #{{application?.id}}
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="settings.uploadDocumentsShown">
                Заявката Ви за кредит е одобрена.
                <br>
                Преди да потвърдите договора си е необходимо да верифицираме вашата самоличност.
            </mat-card-subtitle>

        </mat-card-header>

        <mat-card-content>
            <div class="form-fields-layout" *ngIf="!settings.uploadDocumentsShown">
                <div class="flex-layout-row">
                    <mat-form-field>
                        <mat-label>Статус</mat-label>
                        <input matInput readonly type="text"
                            [value]="'application.statusType.' + application?.status | translate" />
                        <div matPrefix>
                            <itfg-application-status-icon [application]="application"></itfg-application-status-icon>
                        </div>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Главница</mat-label>
                        <input matInput readonly type="text" [value]="application?.principal | currency" />
                        <mat-icon matPrefix>payments</mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex-layout-row">
                    <mat-form-field>
                        <mat-label>Дата на договора</mat-label>
                        <input matInput readonly type="text" [value]="application?.createTime | date : 'dd.MM.YYYY'" />
                        <mat-icon matPrefix>calendar_today</mat-icon>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Договор до</mat-label>
                        <input matInput readonly type="text" [value]="application?.dueDate | date : 'dd.MM.YYYY'" />
                        <mat-icon matPrefix>calendar_month</mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex-layout-row">
                    <mat-form-field>
                        <mat-label>Вноска</mat-label>
                        <input matInput readonly type="text" [value]="application?.installmentAmount | currency" />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Брой вноски</mat-label>
                        <input matInput readonly type="text" [value]="application?.period" />
                        <mat-icon matPrefix>date_range</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <ng-container *ngIf="settings.uploadDocumentsShown">
                <itfg-dynamic-form [fields]="includedFields"
                    (formSubmitted)="onDocumentsSubmit($event)"></itfg-dynamic-form>
            </ng-container>


            <ng-container *ngIf="settings.documentsShown && application?.status === 'APPROVED'">

                <div class="documents-panel" #documentsPanel>
                    <div class="documents-list-container">
                        <mat-list>
                            <div class="document-row" *ngFor="let document of documents">
                                <mat-list-item (click)="documentClicked(document)">
                                    <mat-icon matListItemIcon>description</mat-icon>
                                    <div matListItemTitle>
                                        <span>{{ document.templateName }}</span>
                                    </div>
                                    <div matListItemLine>{{ document.date | date }}</div>
                                </mat-list-item>
                            </div>
                        </mat-list>
                    </div>
                </div>

                <div class="form-actions">
                    <ng-container *ngIf="application?.status === 'APPROVED'">
                        <button mat-raised-button color="primary" (click)="confirmClicked()">
                            {{ 'global.confirm' | translate }}
                        </button>
                        <button mat-raised-button color="warn" (click)="rejectClicked()">
                            {{ 'global.reject' | translate }}
                        </button>
                    </ng-container>
                </div>
            </ng-container>

        </mat-card-content>

    </mat-card>
</div> -->